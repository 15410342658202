@import '../palette.css';
.Login {
    min-height:100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    position: absolute;
    top:0;
    left:0;
    z-index: 1200;
    width:100vw;
    /* background-color: var(--inx_dark-2); */
    background: var(--inx_dark-2);
    background: linear-gradient(73deg, rgba(26,19,61,1) 0%, rgba(0,126,130,1) 100%);
}
.login-wrapper {
    max-width:350px;
    margin:0 auto;
}
.login-wrapper-inner {
    margin:0 10px;
    border:none;
    border-radius:12px;
    padding:25px;
    background-color: var(--inx_dark-2);
    box-shadow: 0px 5px 25px #00000081;
}

.MuiTypography-subtitle1 {
    font-family: 'Open Sans', sans-serif!important;
    font-size: 14px!important;
    font-weight:600!important;
    letter-spacing: 0!important;
}
.loginMainTitle {
    color: var(--inx_green-light);
    font-size:70px;
    line-height: 70px;
    text-align: center;
    font-weight: 800;
    margin:0;
    background: #13ACB1;
    background: -webkit-linear-gradient(to bottom right, #13ACB1 0%, #4331A4 100%);
    background: -moz-linear-gradient(to bottom right, #13ACB1 0%, #4331A4 100%);
    background: linear-gradient(to bottom right, #13ACB1 0%, #4331A4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.loginSubTitle {
    color: var(--inx_light-1);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    font-weight: 400;
    margin:0 0 10px 0;
    text-transform: uppercase;
}

.login-wrapper .fieldLabel {
    background-color: transparent!important;
    border: 1px solid var(--inx_gray-1)!important;
    border-right: 0!important;
}
.login-wrapper .fieldLabel .MuiTypography-subtitle1 {
    color: var(--inx_light-1)!important;
    text-align: center!important;
    font-weight: 400!important;
    text-transform: uppercase;
    font-size: 12px!important;
    padding-top:3px!important;
}
.login-wrapper .labeledField, 
.login-wrapper .MuiInputBase-root {
    background-color: var(--inx_dark-2)!important;
    color: var(--inx_light-1)!important;
    border: none!important;
    outline: 0!important;
}
.login-wrapper .MuiOutlinedInput-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px var(--inx_dark-2) inset;
    border: none!important;
    outline: 0!important;
}
.login-wrapper .labeledField .MuiOutlinedInput-notchedOutline {
    border-color:var(--inx_gray-1)!important;
}
.login-wrapper .labeledField .MuiOutlinedInput-notchedOutline:hover, 
.login-wrapper .labeledField .MuiOutlinedInput-notchedOutline:focus {
    border-color:var(--inx_green)!important;
}
.Login footer p {
    font-size: 12px!important;
    color: #ffffff7c!important;
    width:200px;
    margin:0 auto!important;
}
.Login .MuiButton-containedPrimary {
    color: #FFF;
    background-color: var(--inx_green-dark);
}
.Login .MuiButton-containedPrimary:hover {
    color: #FFF;
    background-color: var(--inx_green);
}


.di_badge {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    background-color: #337AB7;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 15px;
    transition: all 0.5s;
}

.di_badge_check {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    background-color: #65CCB4;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 15px;
}

.darkDialog .MuiDialogTitle-root {
    padding: 16px 20px 0 20px;
}
:root {
    /* Colors: */
    --inx_red-dark: #820023;
    --inx_red-pastel: #FFDAE4;
    --inx_red-light: #FF80A2;
    --inx_red: #DE3562;
    --inx_purple-dark: #4331A4;
    --inx_purple-dark-2: #1a133d;
    --inx_purple-pastel: #D3CCF7;
    --inx_purple-light: #9585EC;
    --inx_purple: #715DDC;
    --inx_orange-dark: #C24D00;
    --inx_orange-pastel: #FFE0CC;
    --inx_orange-light: #FFAC75;
    --inx_orange: #F78133;
    --inx_green-dark: #007E82;
    --inx_green-pastel: #B9F1F2;
    --inx_green-light: #56D9DD;
    --inx_green: #13ACB1;
    --inx_light-1: #D4E0E6;
    --inx_gray-2: #7C8285;
    --inx_gray-1: #485054;
    --inx_dark-3: #1e272b;
    --inx_dark-2: #0D191E;
    --inx_dark-1: #010C11;

    /* Transparency Colors */
    --inx_green-trans: #13acb126;
    --inx_red-trans: #DE356226;
    --inx_purple-trans: #715DDC26;
    --inx_orange-trans: #F7813326;

    }
:root {
    /* Colors: */
    --inx_red-dark: #820023;
    --inx_red-pastel: #FFDAE4;
    --inx_red-light: #FF80A2;
    --inx_red: #DE3562;
    --inx_purple-dark: #4331A4;
    --inx_purple-pastel: #D3CCF7;
    --inx_purple-light: #9585EC;
    --inx_purple: #715DDC;
    --inx_orange-dark: #C24D00;
    --inx_orange-pastel: #FFE0CC;
    --inx_orange-light: #FFAC75;
    --inx_orange: #F78133;
    --inx_green-dark: #007E82;
    --inx_green-pastel: #B9F1F2;
    --inx_green-light: #56D9DD;
    --inx_green: #13ACB1;
    --inx_light-1: #D4E0E6;
    --inx_gray-2: #7C8285;
    --inx_gray-1: #485054;
    --inx_dark-2: #0D191E;
    --inx_dark-1: #010C11;
    }

.container_account-list {
    background: var(--inx_dark-1);
    height: 100vh;
    height: calc(100vh - 64px);
    overflow-x:hidden;
    overflow-y:hidden;
}
.container_account-list .app_bar {
    background: var(--inx_dark-2);
    height: 68px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
}
.container_account-list .MuiDataGrid-footerContainer {
    height:52px;
}
.container_account-list .datagrid_account-list {
    height: calc(100vh - 68px - 52px);
    width: 100vw;
}
.container_account-list footer {
    position:fixed;
    bottom:0;
    left:0;
    color: var(--inx_light-1);
}
.container_account-list footer .MuiTypography-colorTextSecondary {
    color: var(--inx_gray-2);
    font-weight:200;
}

.container_account-list .datagrid_account-list .dataVal_account {
    color: var(--inx_green);
    font-weight: 700;
    display: block;
    width: 100%;
    text-align: start;
}
.container_account-list .datagrid_account-list .dataVal_brand {
    color: var(--inx_red-light);
    font-weight:500;
}
.container_account-list .datagrid_account-list .dataVal_shipper {
    color: var(--inx_orange-light);
    font-weight:400;
}


.container_account-list .app_bar_items {
    display:flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width:100%;
    padding:0 10px;
}
.container_account-list .app_bar_items .inx_btn_red {
    background-color: var(--inx_red);
    color:#fff!important;
}
.container_account-list .app_bar_items .inx_btn_red:hover {
    background-color: var(--inx_red-light);
}
.container_account-list .app_bar_items .inx_btn_red.Mui-disabled {
    opacity:0.5;
}
.container_account-list .app_bar_items .inx_btn_red .hide-mobile { white-space: nowrap; }
@media (max-width: 991.98px) {
    .container_account-list .app_bar_items .inx_btn_red {
        padding:6px;
        min-width:32px;
    }
    .container_account-list .app_bar_items .inx_btn_red .hide-mobile { 
        display:none!important; 
    }
    .container_account-list .app_bar_items .inx_btn_red .MuiButton-endIcon {
        display: inherit;
        margin-left: 0;
        margin-right: 0;
    }
}

.container_account-list .darkDataGrid .MuiDataGrid-row .MuiDataGrid-cell:focus, 
.container_account-list .darkDataGrid .MuiDataGrid-row .MuiDataGrid-cell:focus-within, 
.container_account-list .darkDataGrid .MuiDataGrid-row .MuiDataGrid-cell:focus-visible {
    outline: none!important;
}

.container_account-list .darkDataGrid .MuiDataGrid-row:hover,
.container_account-list .darkDataGrid .MuiDataGrid-row.Mui-selected {
    background-color: var(--inx_dark-2)!important;
}
.container_account-list .darkDataGrid .MuiDataGrid-row:hover .MuiDataGrid-cell,
.container_account-list .darkDataGrid .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell {
    color: #fff;
    border-bottom: 1px solid var(--inx_green-dark)!important;
}

.container_account-list .darkDataGrid .MuiDataGrid-row:hover .dataVal_account,
.container_account-list .darkDataGrid .MuiDataGrid-row.Mui-selected .dataVal_account {
    color: var(--inx_green-light);
}
.container_account-list .darkDataGrid .MuiDataGrid-row:hover .dataVal_brand,
.container_account-list .darkDataGrid .MuiDataGrid-row.Mui-selected .dataVal_brand {
    color: var(--inx_red-pastel);
}
.container_account-list .darkDataGrid .MuiDataGrid-row:hover .dataVal_shipper,
.container_account-list .darkDataGrid .MuiDataGrid-row.Mui-selected .dataVal_shipper {
    color: var(--inx_orange-pastel);
}

.MuiGridMenu-root .MuiListItem-button {
    color: var(--inx_light-1);
}
.MuiGridMenu-root .MuiListItem-button:hover {
    color: var(--inx_dark-1);
    background-color: var(--inx_green-dark);
}


.container_account-list .app_bar .MuiInputBase-root, 
.container_account-list .app_bar .MuiInputBase-input, 
.container_account-list .app_bar .MuiFormLabel-root {
    color: var(--inx_green);
}
.container_account-list .app_bar .MuiOutlinedInput-notchedOutline, 
.container_account-list .app_bar .MuiOutlinedInput-notchedOutline:hover {
    border-color: var(--inx_green)!important;
}



/** buttons **/

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    border-radius: .25rem;
}

.btn-primary, .btn-primary.disabled, .btn-primary:disabled  {
    background-color:#13ACB1;
    border-color:#13ACB1;
    color:#FFF;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color:#56D9DD;
    border-color:#56D9DD;
}
.btn-warning {
    background-color: #F78133;
    border-color:#F78133;
    color:#fff;
}
.btn-warning:hover, .btn-warning:active, .btn-warning:focus {
    background-color: #FFAC75;
    border-color:#FFAC75;
    color:#fff;
}
.btn-success {
    background-color: #715DDC;
    border-color:#715DDC;
    color:#FFF;
}
.btn-success:hover, .btn-success:active, .btn-success:focus {
    background-color: #9585EC;
    border-color:#9585EC;
}
.btn-danger {
    background-color: #DE3562;
    border-color:#DE3562;
    color:#FFF;
}
.btn-danger:hover, .btn-danger:active, .btn-danger:focus {
    background-color: #FF80A2;
    border-color:#FF80A2;
}
.btn-outline-light {
    border-color:rgb(255,255,255,0.25);
}
.btn-outline-primary {
    border-color:#13ACB1;
    color:#13ACB1;
}
.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus {
    background-color:#56D9DD;
    border-color:#56D9DD;
    color:#FFF;
}

/** slide icons **/

.side-icons {
    position:fixed;
    top:64px;
    bottom:0;
    left:0;
    z-index:10;
    background:#010C11;
    width:38px;
}
@media (max-width: 599.98px) {
    .side-icons { top:56px; }
}

.side-icons .btn.slide-icon {
    width:36px;
    height:36px;
    border-radius:0;
    display:block;
    padding:0;
    line-height:36px;
    margin:0 1px 1px;
    text-align: center;
}
.side-icons .btn.slide-icon span {
    display:none; opacity:0; padding-left:16px; transition:opacity 1s;
    text-transform:uppercase;
}

/* Transparent style Drawers */

.transparentDrawer {
    /*max-width:360px;*/
    width:380px;
    overflow:hidden;
}
.transparentDrawer .MuiPaper-root {
    background-color: transparent!important;
    top:65px;
    left:38px;
    width:380px;
    border-radius:0!important;
}
.transparentDrawer .drawerList {
    background-color: rgba(0,0,0,0.8);
    -webkit-box-shadow: 0px 4px 15px 5px rgba(0,0,0,0.3);
    box-shadow: 0px 4px 15px 5px rgba(0,0,0,0.3);
}
.transparentDrawer .drawerList .MuiTypography-h5 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    padding:0 16px;
}
.transparentDrawer .drawerList .listItemWrapper {
    width:360px;
}
.transparentDrawer .MuiListItem-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom:0;
}
.drawerList .MuiList-root .MuiTypography-root {
    color: #fff;
    font-weight:600;
}

.transparentDrawer .View.drawerList .drawerHeader {
    background-color: #F78133;
}
.transparentDrawer .View.drawerList .MuiListItem-button:hover {
    background-color: #c24e0062;
}
.transparentDrawer .Dispatch.drawerList .drawerHeader {
    background-color: #DE3562;
}
.transparentDrawer .Dispatch.drawerList .MuiListItem-button:hover {
    background-color: #82002391;
}
.transparentDrawer .Service.drawerList .drawerHeader {
    background-color: #715DDC;
}
.transparentDrawer .Service.drawerList .MuiListItem-button:hover {
    background-color: #4231a47a;
}
.transparentDrawer .Rating.drawerList .drawerHeader {
    background-color: #13ACB1;
}
.transparentDrawer .Rating.drawerList .MuiListItem-button:hover {
    background-color: #007e827e;
}

.transparentDrawer .MuiDrawer-paperAnchorDockedLeft {
    border-right: 0!important;
}

@import '../palette.css';
/* Subheader */
.subheaderJobDetail { border-bottom:1px solid #48505484; }

.subheaderJobDetail .MuiButtonBase-root { background-color:#010c1151; margin-left:4px; }
.subheaderJobDetail .MuiButtonBase-root:hover { background-color:#010c11a1; }
.subheaderJobDetail .MuiButtonBase-root .MuiSvgIcon-root { color:#D4E0E6; }
.subheaderJobDetail .MuiButtonBase-root:hover .MuiSvgIcon-root { color:#fff; }

.subheaderJobDetail .MuiTypography-body2 {
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    color:#fff;
    margin-bottom:5px;
    border-bottom:1px solid rgba(255, 255, 255, 0.18);
    opacity:0.5;
}
.subheaderJobDetail .MuiTypography-body1 {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1;
    color:#fff;
}

/* Job notes */
.fieldJobNote.MuiAlert-root {
    border-color:#D4E0E6;
    background-color: #0D191E;
    color:#D4E0E6;
    display:block;
    overflow:hidden;
    margin:1px 0;
}
.fieldJobNote.MuiAlert-root { padding:0; }
.fieldJobNote .MuiAlert-message {
    width:100%;
    padding:0;
    display:flex;
    align-items: stretch;
}
.fieldJobNote .notes {
    padding:8px 8px 8px 15px;
}
.fieldJobNote .speechBubble {
    position: relative;
    background: #007E82;
    color:#fff;
    font-weight:600;
    padding:8px 15px;
}
.fieldJobNote .speechBubble:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-left-color: #007E82;
    border-right: 0;
    margin-top: -6px;
    margin-right: -6px;
}

/* Address Cards */

.darkCardBlock .MuiCard-root, .containerGallery {
    border-radius: 6px!important;
    border-width: 1px;
    border-style: solid;
    box-shadow: rgb(0 0 0) 0px 0px 10px;
    border-color: #13ACB1;
    background-color: #007e825e!important;
    overflow: hidden;
}
.darkCardBlock.imagesCardBlock .MuiCard-root, .containerGallery { 
    border-color: #715DDC;
    background-color:#010C11!important;
}
.darkCardBlock .MuiCard-root .MuiCardHeader-root {
    background-color: transparent;
    color:#fff;
}
.darkCardBlock .MuiCard-root .MuiCardHeader-root p {
    font-size:16px!important; line-height: 24px!important; font-weight:600;
}
.darkCardBlock .MuiCard-root .MuiCardContent-root {
    color:#D4E0E6!important;
    background-color: #0D191E;
}
.darkCardBlock .MuiCard-root .MuiCardContent-root p {
    font-size:14px!important; line-height: 24px!important;
}
.darkCardBlock.shipperCardBlock .MuiCard-root .MuiCardContent-root, 
.darkCardBlock.consigneeCardBlock .MuiCard-root .MuiCardContent-root {
    background-repeat: no-repeat; background-position: 10px 10px; background-size:40px auto; padding-left:50px;
}
.darkCardBlock.shipperCardBlock .MuiCard-root .MuiCardContent-root {
    background-image: url('./images/pin_start.png');
}
.darkCardBlock.consigneeCardBlock .MuiCard-root .MuiCardContent-root {
    background-image: url('./images/pin_purple_end.png');
}
.darkCardBlock.imagesCardBlock .MuiCard-root .MuiCardContent-root { 
    padding:10px; display: flex; justify-content: space-between; align-items: center;
}
.darkCardBlock.imagesCardBlock .MuiCard-root .MuiButton-root { 
    border-color: #715DDC!important;
    width:30px!important; min-width:30px!important; max-width:30px!important; height:30px!important; border-radius: 100%!important;
    background-color: #715DDC!important;
    color:#fff!important;
    padding:5px;
}

/* .darkCardBlock .fieldLabel {
    background-color: #010C11!important;
} */
.darkCardBlock .MuiOutlinedInput-root {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
    border-color: var(--inx_dark-3)!important;
}
.darkCardBlock .editAddressForm {
    border-color: var(--inx_dark-3)!important;
}
.darkCardBlock .MuiOutlinedInput-input {
    padding-top: 10.5px!important;
    padding-bottom: 10.5px!important;
}
.darkCardBlock .MuiListItem-button:hover {
    background-color: var(--inx_green-trans)!important;
}
.darkCardBlock .MuiListItem-button {
    color: var(--inx_light-1)!important;
}
.darkCardBlock .MuiListItem-button .panel-item-title {
    color: var(--inx_green)!important;
}


.element {
    width: 100%;
    max-width: calc(100vw - 80px);
    margin: 10px auto;
}
  

/* address list UI */
.panel-list {
    max-height: 300px;
    min-height: 300px;
    overflow-y: scroll;
    margin: 2% 0;
}

.panel-list .panel-list-container {
    padding: 0px;
    margin: 0px 2px 0px 0px;
    border-radius: 0.25rem;
    border: 1px solid rgba(0,0,0,.125);
}

.panel-list .panel-list-container .panel-list-item {
    margin: 0px;
    padding: 1px;
}

/* scrollbar */
.panel-list::-webkit-scrollbar-track
{
    -webkit-box-shadow: none;
    border-radius: 10px;
    background-color:var(--inx_dark-1);
}
.panel-list::-webkit-scrollbar
{
    width: 10px;
    background-color:var(--inx_dark-1);
}
.panel-list::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color:var(--inx_green-dark);
}

.tooltip-inner {
    text-align:left!important;
    background-color:#337AB7!important;
}
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    border-top-color:#337AB7!important;
}



.MuiStepLabel-labelContainer, 
.MuiStepLabel-label.MuiStepLabel-completed, 
.MuiStepLabel-label { color:#fff!important; }





/* .editJobForm .MuiFormControl-root.MuiTextField-root {  */
    /* background-color:transparent!important; */
    /* border:1px solid #fff; */
/* } */
.editJobForm {
    overflow-x: auto;
}
.editJobForm .MuiInputBase-root.Mui-disabled {
    color: #fff;
    cursor: default;
}
.editableJobField {
    background-color: #13ACB1; 
    border-radius:4px; 
    color:#fff!important;
    border:1px solid #13ACB1!important;
}
.editJobForm .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense, 
.editJobForm .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense textarea {
    padding: 0;
}
.editJobForm .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense textarea {
    margin: 0;
    background: transparent;
    border:0;
    padding:10px 15px;
}
.editJobForm .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense * {
    border:0;
}

/* Gallery ---------------- */

.containerGallery {
    position: relative;
    background-color: #010C11;
    margin-top: 0;
    height: auto;
    width: 100%;
    top: 0;
    right: 0;
}

/* .containerGallery {
    z-index: 9990;
    position: fixed;
    background-color: #010C11;
    margin-top: 0;
    height: 100vh;
    width: 25%;
    top: 0;
    right: 0;
} */
@media (max-width: 1280px) {
    /* .containerGallery {
        z-index: 9990;
        position: static;
        background-color: #010C11;
        margin-top: 0;
        height: auto;
        width: 100%;
        top: 0;
        right: 0;
    } */
  }

/* Map Street-view Dialog ---------------- */

.gm-style .gm-style-iw-c {
    background-color: #010C11;
    border-radius: 8px;
    padding: 12px 24px 12px 12px!important;
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
}
.gm-style .gm-style-iw-d {
    overflow: auto!important;
    overflow-y: auto!important;
    overflow-x: hidden!important;
}
.gm-ui-hover-effect {
    opacity: .8!important;
    top: -3px!important;
    right: -3px!important;
}
.gm-ui-hover-effect:hover {
    opacity: 1!important;
}
.gm-ui-hover-effect>span {
    background-color: #FF80A2;
}
.gm-style .gm-style-iw-tc::after {
    background: #010C11;
}

.MuiPopover-root.MuiModal-root .MuiPaper-root {
    padding:12px!important;
    font-size:15px!important;
}
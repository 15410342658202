/* Top bar ====================================== */

/* .container_message-history .MuiInput-underline:before {
    border-bottom: 1px solid var(--inx_green-dark);
}
.container_message-history .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid var(--inx_green-light);
}
.container_message-history .MuiInput-underline:after {
    border-bottom: 1px solid var(--inx_red);
}
.appBarDark .MuiInputBase-root.MuiInput-underline.MuiInputBase-adornedEnd {
    padding:0 10px;
} */

.getMessagesBtn.MuiButton-contained, 
.getMessagesBtn.MuiButton-contained:hover {
    background-color: transparent;
    color: var(--inx_light-1);
}
.getMessagesBtn.MuiButton-containedSizeLarge {
    padding-right: 10px;
}
.getMessagesBtn.MuiButton-contained span span {
    word-wrap: none;
    padding-right:10px;
    opacity: 0.6;
    transition: all 0.5s;
}
.getMessagesBtn.MuiButton-contained:hover span span {
    opacity: 1;
}
@media (max-width: 767.98px) {
    .getMessagesBtn.MuiButton-contained span span {
        display: none;
    }
}
.getMessagesBtn.MuiButton-contained svg {
    background-color: var(--inx_red);
    padding:10px;
    border-radius: 40px;
    transition: all 0.5s;
}
.getMessagesBtn.MuiButton-contained:hover svg {
    background-color: var(--inx_red-dark);
}


/* .MuiCalendarPicker-root {
    background-color: var(--inx_dark-1);
    color: var(--inx_light-1);
}
.MuiCalendarPicker-root .MuiButtonBase-root, 
.MuiCalendarPicker-root .MuiTypography-root {
    color: var(--inx_light-1);
}
.MuiCalendarPicker-root .MuiPickersDay-root {
    background-color: var(--inx_green-trans);
}
.MuiCalendarPicker-root .MuiPickersDay-root:hover {
    background-color: var(--inx_green-dark);
}
.MuiCalendarPicker-root .MuiPickersDay-root.Mui-selected, 
.MuiCalendarPicker-root .MuiYearPicker-root .Mui-selected {
    background-color: var(--inx_red)!important;
} */


/* Data table elements ====================================== */

@media (min-width: 959.8px) {
    .container_message-history .px-lg {
        padding:0 15px;
        padding-top: 10px;
    }
}

.container_message-history .timestamp {
    font-size:12px;
}
.container_message-history .replyButton {
    color:var(--inx_red);
    background-color:var(--inx_red-trans);
}
.container_message-history .replyButton:hover {
    color:var(--inx_red-light);
    background-color:var(--inx_red-dark);
}
.container_message-history .linkDriver {
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.container_message-history .linkDriver a {
    color:var(--inx_red-light);
}
.container_message-history .linkDriver a:hover {
    color:var(--inx_red-pastel);
}

.container_message-history .dx-datagrid .dx-row-alt > td, 
.container_message-history .dx-datagrid .dx-row-alt > tr > td {
    background-color: var(--inx_dark-2);
    border-top: var(--inx_dark-2);
    border-bottom: var(--inx_dark-2);
}
.container_message-history .dx-datagrid-content .dx-datagrid-table .dx-row > td, 
.container_message-history .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
    vertical-align: middle;
}
@import './palette.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
body {
  margin: 0;
  background-color: var(--inx_dark-1);
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
iframe { border:0; }
.select-search {
  position: relative; font-family: "Open Sans", sans-serif; box-sizing: border-box;
}
.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

footer { pointer-events: none; }

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input    background: #fff; border: 1px solid transparent;  padding: 0 40px 0 16px;
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 20px 0 26px;
  background: transparent;
  border: 1px solid #808080;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  margin-bottom: 6px;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

/* background: #2fcc8b; */
.select-search__option.is-selected {
  background: #ebebeb;
  color: #fff;
}

/*background: rgba(47, 204, 139, 0.1); */
.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgb(227,227,227);
}

/*  background: #2eb378 */
.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #ebebeb;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");*/
  /*background-size: 11px;*/
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

/*.select-search:not(.is-loading):not(.select-search--multiple)*/
/*  .select-search__value::after {*/
/*  transform: rotate(45deg);*/
/*  border-right: 1px solid #fff;*/
/*  border-bottom: 1px solid #fff;*/
/*  pointer-events: none;*/
/*}*/

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

/*border-color: #2fcc8b; */
.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color:  #e3e3e3;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

/* =============================================== Color classes */

.colorPrimary { color:#13ACB1!important; }

/* =============================================== UI in Login */

.labeledField .MuiInputBase-root {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.labeledField .MuiOutlinedInput-notchedOutline {
}
.fieldLabel {
  overflow:hidden;
  white-space: nowrap;
}

/* =============================================== UI in Main Header */
/* Fix top menu to the top of viewport */
#mainHeader header.MuiAppBar-root {
  position:fixed!important;
  z-index:1199;
  top:0!important; left:0!important; right:0!important;
  width:100%;
  background: #010C11;
  border-bottom:1px solid #4850547d;
}

/* move page content below fixed nav ------------------------- */
body {padding-top:64px;}
@media (max-width: 599.98px) { body {padding-top:56px;} }
/* ----------------------------------------------------------- */

#mainHeader .MuiIconButton-root { color: #13ACB1; }

#mainHeader .MuiOutlinedInput-notchedOutline {
  border-color: #007e827d !important;
}

#mainHeader .MuiFormLabel-root {
  color:#13ACB1!important;
}

/*#mainHeader .MuiButton-outlined {*/
/*  border: 1px solid #fff;*/
/*  padding: 0px 15px;*/
/*  line-height: 38px;*/
/*  color: #fff;*/
/*}*/
#mainHeader .MuiInputBase-input { color:#D4E0E6!important; font-size: 13px; font-family: 'Open Sans', sans-serif; } 
#mainHeader .select-search__input {
  height: 40px;
  width: 100%;
  padding: 0 40px 0 15px;
  margin:0!important;
  background: transparent;
  border: 1px solid #007e827d;
  box-shadow:none;
  color:#D4E0E6!important;
}
#mainHeader .select-search__input::placeholder { color:#13ACB1; } 
#mainHeader .fieldDropDownIcon { position:relative; }
#mainHeader .fieldDropDownIcon svg { position:absolute; right:7px; top:50%; margin-top:-12px; pointer-events: none; cursor:pointer; }
#mainHeader .MuiInputBase-root svg { color:#fff; } 

.whiteDropDownItem,
.whiteDropDownItem:hover { background-color: #fff!important; }

.whiteDropDownItem .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input { color: #007e827d }
.whiteDropDownItem .MuiInputLabel-root.Mui-focused { color: #007e827d }
.whiteDropDownItem .MuiInputLabel-root { color: #007e827d }
.whiteDropDownItem .MuiOutlinedInput-input { color: #007e827d }

/* =================================================== Wrappers */

.fullWrapper {
  background-color: var(--inx_dark-1);
  min-height: 100vh;
  min-height: calc(100vh - 64px);
}
@media (max-width: 599.98px) { 
  .fullWrapper {
    min-height: 100vh;
    min-height: calc(100vh - 56px);
  }
}

/* =============================================== Colors */

.text-primary { color:#13ACB1; }

/* =============================================== Typography */

.text-uppercase { text-transform: uppercase }
.title-small { font-size: 16px; font-weight: 600; letter-spacing:1px; }

/* =============================================== Buttons */

.MuiButton-root {
 box-shadow: none!important;
}

.darkDialog .MuiButton-root, 
.MuiDialog-root .MuiButton-root {
  background-color:#13ACB1; color:#010C11; font-weight:600;
}
.darkDialog .MuiButton-root:hover, 
.MuiDialog-root .MuiButton-root:hover {
  background-color:#56D9DD; color:#010C11;
}
.darkDialog .MuiButton-root.MuiButton-textSecondary, 
.MuiDialog-root .MuiButton-root.MuiButton-textSecondary {
  background-color:#DE3562; color:#010C11; font-weight:600;
}
.darkDialog .MuiButton-root.MuiButton-textSecondary:hover, 
.MuiDialog-root .MuiButton-root.MuiButton-textSecondary:hover {
  background-color:#FF80A2; color:#010C11;
}

/* ================================ AppBar DARK: Stat Bar Wrapper */

.statsWrapper {
  flex-wrap: nowrap;
}
.statsWrapper .statBox {
  width:100%; margin:5px;
  box-shadow: 0px 0px 10px #000000;
  background-color:#010C11;
}
/* @media (max-width: 1199.98px) { */
@media (max-width: 1750px) {
  .statsWrapper {
      flex-wrap: wrap;
  }
  .statsWrapper .statBox {
    width:10.08%; margin:0.5%; overflow:hidden;
  }
}
@media (max-width: 767.98px) {
  .statsWrapper .statBox {
    width:23%; margin:1%;
  }
  .statsWrapper .statBox:nth-of-type(17), 
  .statsWrapper .statBox:nth-of-type(18) {
    width:48%; margin:1%;
  }
}
@media (max-width: 575.98px) {
  .statsWrapper .statBox {
    width:48%; margin:1%;
    display:flex; align-items:center; justify-content:space-between;
  }
}
.statBox.statBoxPrimary { background: transparent linear-gradient(344deg, #010C11 0%, #007e8229 100%) 0% 0% no-repeat padding-box; border:1px solid #13ACB1; }
.statBox.statBoxWarning { background: transparent linear-gradient(169deg, #f781331d 0%, #010C11 100%) 0% 0% no-repeat padding-box; border:1px solid #F78133; }
.statBox.statBoxDanger { background: transparent linear-gradient(169deg, #de356222 0%, #010C11 100%) 0% 0% no-repeat padding-box; border:1px solid #DE3562; }
.statBox.statBoxSecondary { background: transparent linear-gradient(169deg, #705ddc27 0%, #010C11 100%) 0% 0% no-repeat padding-box; border: 1px solid #9585EC; }

.appBarDark, .MuiAppBar-colorPrimary.appBarDark {
  background-color: #0D191E;
  color:#D4E0E6;
}
.appBarDark .MuiSwitch-root .MuiSwitch-thumb {
  color: #007E82;
}
.appBarDark .MuiSwitch-root .MuiSwitch-track {
  background-color: #485054!important;
}
.appBarDark .MuiSwitch-root .Mui-checked .MuiSwitch-thumb {
  color: #B9F1F2;
}

/* ================================ DRIVER page */

.statsWrapperDriver {
  display:flex; flex-wrap: nowrap; padding:10px; transition: all 0.5s;
  border-top:1px solid rgb(255,255,255,0.1);
}
.statsWrapperDriver .statBox {
  padding: 10px; border-radius: 6px; box-sizing: border-box;
  display:flex; justify-content: space-between; align-items: center;
}
@media (max-width: 1750px) {
  .statsWrapperDriver {
      flex-wrap: wrap;
  }
  .statsWrapperDriver .statBox {
    width:19%; margin:0.5%; overflow:hidden;
  }
  .statsWrapperDriver .statBox:nth-of-type(1), 
  .statsWrapperDriver .statBox:nth-of-type(2), 
  .statsWrapperDriver .statBox:nth-of-type(3) {
    width:32.25%;
  }
}
.statsWrapperDriver .statBox .titleBox {
  color: #13ACB1; font-size:12px; text-transform: uppercase; padding-right: 16px;
}
.statsWrapperDriver .statBoxPrimary .titleBox { color: #13ACB1; }
.statsWrapperDriver .statBoxSecondary .titleBox { color: #9585EC; }
.statsWrapperDriver .statBoxWarning .titleBox { color: #F78133; }
.statsWrapperDriver .statBoxDanger .titleBox { color: #DE3562; }
.statsWrapperDriver .statBox .descBox {
  color: #D4E0E6; font-size:16px; font-weight: 600; overflow:hidden; white-space: nowrap;
}

/* Dark App Bar TOGGLES */

.mapTogglesWrapper { display:flex; align-items: center; margin-left:auto; }
.mapTogglesWrapper .MuiFormControlLabel-root { padding-left:20px; }
.appBarDark .MuiFormControlLabel-label {
  color: #D4E0E6; font-size:12px; text-transform: uppercase;
}

/* Dark POPOVERS */

.popoverDark .MuiPopover-paper { background-color: #0D191E!important; color:#D4E0E6; font-family: 'Open Sans', sans-serif!important; }

.MuiPopover-root .MuiPaper-root, 
.MuiPopover-root .MuiListItem-button  {
  color: #D4E0E6;
  background-color: #0D191E;
}
.MuiPopover-root .MuiListItem-button:hover, .MuiPopover-root .MuiListItem-button.Mui-selected:hover {
  color: #010C11;
  background-color: #56D9DD;
}
.MuiPopover-root .MuiListItem-button.Mui-selected {
  color: #B9F1F2;
  background-color: #007e827d;
}

/* Dark CALENDAR PICKER */

.MuiPickersBasePicker-container .MuiPickersCalendarHeader-switchHeader .MuiPickersCalendarHeader-iconButton {
  background-color: #007e82a8;
  color:#010C11;
  padding:6px;
}
.MuiPickersBasePicker-container .MuiPickersCalendarHeader-switchHeader .MuiPickersCalendarHeader-iconButton:hover {
  background-color: #13ACB1;
  color:#010C11;
}
.MuiPickersCalendarHeader-daysHeader {
  background-color: #007e8259;
  border-radius:4px;
}
.MuiPickersCalendarHeader-daysHeader .MuiPickersCalendarHeader-dayLabel, 
.MuiPickersDay-day .MuiTypography-root, 
.MuiPickersClock-clock .MuiPickersClockNumber-clockNumber  {
  color:#D4E0E6;
}

/* Dark DIALOG */

.darkDialog .MuiPaper-root.MuiDialog-paper, 
.MuiDialog-root .MuiPaper-root.MuiDialog-paper {
  border:1px solid #007E82;
}
.darkDialog .MuiDialogContent-root, 
.MuiDialog-root .MuiDialogContent-root {
  padding: 20px 20px 0!important;
}
.darkDialog .MuiDialogActions-root, 
.MuiDialog-root .MuiDialogActions-root {
  padding: 10px 20px 20px!important;
}
.MuiDialog-root .MuiTypography-colorTextSecondary, 
.MuiDialog-root .MuiPaper-root {
  color: #D4E0E6;
}

/* Dark App FORMS */

.darkDataGrid .MuiFormControl-root.MuiTextField-root { background-color: transparent!important; }

.appBarDark .MuiInputBase-root, .appBarDark .MuiFormLabel-root, 
.darkForm .MuiInputBase-root, .darkForm .MuiFormLabel-root, 
.darkDataGrid .MuiInputBase-root, .darkDataGrid .MuiFormLabel-root, 
.darkDialog .MuiInputBase-root, .darkDialog .MuiFormLabel-root, 
.MuiDialog-root .MuiInputBase-root, .MuiDialog-root .MuiFormLabel-root {
  color:#D4E0E6;
}
.appBarDark .MuiInputBase-root,  
.darkForm .MuiInputBase-root, 
.darkDataGrid .MuiInputBase-root, 
.darkDialog .MuiInputBase-root, 
.MuiDialog-root .MuiInputBase-root, 
.darkForm textarea {
  background-color:#010C11;
  background: transparent linear-gradient(344deg, #010C11 0%, #007e8229 100%) 0% 0% no-repeat padding-box;
}
.darkForm textarea {
  border-color: var(--inx_green);
  padding:10px;
  width:100%;
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--inx_light-1);
  margin: 8px 4px 4px;
}
.darkForm textarea::placeholder { color: var(--inx_light-1); font-family: 'Open Sans', sans-serif; }

.appBarDark .MuiOutlinedInput-notchedOutline, .appBarDark .MuiOutlinedInput-notchedOutline:hover, 
.darkForm .MuiOutlinedInput-notchedOutline, .darkForm .MuiOutlinedInput-notchedOutline:hover, 
.darkDataGrid .MuiOutlinedInput-notchedOutline, .darkDataGrid .MuiOutlinedInput-notchedOutline:hover, 
.darkDialog .MuiOutlinedInput-notchedOutline, .darkDialog .MuiOutlinedInput-notchedOutline:hover, 
.MuiDialog-root .MuiOutlinedInput-notchedOutline, .MuiDialog-root .MuiOutlinedInput-notchedOutline:hover {
  border-color: #485054!important;
}

.MuiInputAdornment-root .MuiIconButton-root, .MuiInputAdornment-root .MuiTypography-root {
  color:#56D9DD!important;
}

.darkForm .MuiCheckbox-root {
  color: #007E82;
}
.darkForm .MuiCheckbox-colorSecondary.Mui-disabled {
  color: rgba(255, 255, 255, 0.26);
}

/* Dark App Bar ICON BUTTONS */

.appBarDark .btnPrimary, .darkForm .btnPrimary { color: #13ACB1; }
.appBarDark .btnPrimary:hover, .darkForm .btnPrimary:hover { color: #B9F1F2; background-color: #007e8238; }

/* ================================ dataGrid DARK */

.darkDataGrid {
  background-color: #010C11;
  /* height: 90vh; */
}

.darkDataGrid .MuiDataGrid-root { padding:0; border:0px solid transparent!important; }
.darkDataGrid .MuiDataGrid-main, .darkDataGrid .MuiDataGrid-root {
  border:0px solid transparent!important;
  font-family: 'Open Sans', sans-serif;
}

.darkDataGrid .MuiDataGrid-columnHeaders, .darkDataGrid  .MuiDataGrid-root .MuiDataGrid-columnsContainer { border-bottom:0px solid transparent!important; background-color:#007E82; border-radius: 0; }
.darkDataGrid .MuiDataGrid-columnSeparator { opacity:0!important; }
.darkDataGrid .MuiDataGrid-columnHeader { color:#fff; font-weight:700; }
.darkDataGrid .MuiDataGrid-columnHeader:hover { background-color:#13ACB1; }
.darkDataGrid .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer { padding: 0; }

.darkDataGrid .MuiDataGrid-row { background-color: #0d191e8e; border-radius: 0; transition: background-color 0.5s; }
.darkDataGrid .MuiDataGrid-row:hover, .darkDataGrid .MuiDataGrid-row.Mui-selected { background-color: #010C11!important;}

.darkDataGrid .MuiDataGrid-cell { border-bottom:1px solid #010C11!important; color:#D4E0E6; font-weight:300; }
.darkDataGrid .MuiDataGrid-row .MuiIconButton-root { color:#13ACB1; }

.darkDataGrid .MuiDataGrid-footerContainer {border-top:none!important;}
.darkDataGrid .MuiTablePagination-root { color:#7C8285; }
.darkDataGrid .MuiTablePagination-root .MuiSvgIcon-root { color:#7C8285; }

.darkDataGrid .MuiDataGrid-cell .iconButtonClear { color:#DE3562; background-color:transparent; }
.darkDataGrid .MuiDataGrid-cell .iconButtonClear:hover { color:#FF80A2; background-color:transparent!important; }
.darkDataGrid .MuiDataGrid-cell .iconButtonReply { color:#007E82; background-color:transparent; }
.darkDataGrid .MuiDataGrid-cell .iconButtonReply:hover { color:#56D9DD; background-color:transparent!important; }

.darkDataGrid .MuiDataGrid-cell .jobIDLink, 
.darkDataGrid .MuiDataGrid-cell .driverNumLink { color:#D4E0E6; font-weight:700; cursor:pointer; }
.darkDataGrid .MuiDataGrid-cell .jobIDLink:hover, 
.darkDataGrid .MuiDataGrid-cell .driverNumLink:hover { color:#13ACB1; }

.darkDataGrid .MuiDataGrid-cell .priorityTop { color:#DE3562; font-weight:700; cursor:pointer;}
.darkDataGrid .MuiDataGrid-cell .priorityTop:hover { color:#FF80A2; }
.darkDataGrid .MuiDataGrid-cell .priorityHigh { color:#F78133; font-weight:700; cursor:pointer;}
.darkDataGrid .MuiDataGrid-cell .priorityHigh:hover { color:#FFAC75; }
.darkDataGrid .MuiDataGrid-cell .priorityMedium { color:#13ACB1; font-weight:700; cursor:pointer;}
.darkDataGrid .MuiDataGrid-cell .priorityMedium:hover { fill:#56D9DD; }
.darkDataGrid .MuiDataGrid-cell .priorityLow { color:#715DDC; font-weight:700; cursor:pointer;}
.darkDataGrid .MuiDataGrid-cell .priorityLow:hover { color:#9585EC; }

.darkDataGrid .MuiDataGrid-cell .MuiCheckbox-root.MuiCheckbox-colorPrimary { color:#13ACB1; }
.darkDataGrid .MuiDataGrid-cell .MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-disabled { color:#485054; }

.darkDataGrid .MuiDataGrid-rowCount { color:#D4E0E6; }

.darkDataGrid .claimButtonWrapper { width:60px; position:relative; cursor:pointer; opacity:1; }
/* .darkDataGrid .claimButtonWrapper:hover { opacity:1; } */
.darkDataGrid .claimButtonValue {
  width:100%;
  position:absolute;
  text-align:center;
  top:50%;
  margin-top:-8px;
  font-size:12px;
  color:#000;
  font-weight:800;
  pointer-events: none;
}
.darkDataGrid .claimButtonIcon { margin:0 auto; display:block; }
.darkDataGrid .claimButtonIcon.priorityTop { fill:#DE3562; }
.darkDataGrid .claimButtonIcon.priorityTop:hover { fill:#FF80A2; }
.darkDataGrid .claimButtonIcon.priorityHigh { fill:#F78133; }
.darkDataGrid .claimButtonIcon.priorityHigh:hover { fill:#FFAC75; }
.darkDataGrid .claimButtonIcon.priorityMedium { fill:#13ACB1; }
.darkDataGrid .claimButtonIcon.priorityMedium:hover { fill:#56D9DD; }
.darkDataGrid .claimButtonIcon.priorityLow { fill:#715DDC; }
.darkDataGrid .claimButtonIcon.priorityLow:hover { fill:#9585EC; }

.root { background-color: #010C11; height:100%; width:100%; min-height: 100vh; color:#D4E0E6; }

/* ====================== Custom Scroll Bar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--inx_dark-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #04595c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--inx_green-dark);
}


/* (D) ADDING "SPRING EFFECT" */
@keyframes spring { 
  0% { transform: scale(1) translateY(0); }
  10% { transform: scale(1.2, 0.6); }
  30% { transform: scale(0.8, 1.1) translateY(-50px); }
  50% { transform: scale(1) translateY(0); }
  100% { transform: translateY(0); }
}
 
.spring{
  animation: spring 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

/* (C) ADDING "GRAVITY" */
@keyframes gbounce { 
  0% { transform: translateY(0); }
  30% { transform: translateY(-50px); }
  50% { transform: translateY(0); }
  100% { transform: translateY(0); }
}

.gbounce {
  animation: gbounce 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
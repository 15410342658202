.base-timer {
  position: relative;
  width: 40px;
  height: 40px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed.green {
  stroke-width: 5px;
  stroke: #13ACB1;
}

.base-timer__path-elapsed.orange {
  stroke-width: 5px;
  stroke: #F78133;
}

.base-timer__path-elapsed.red {
  stroke-width: 5px;
  stroke: #DE3562;
}


.base-timer__path-elapsed {
  stroke-width: 5px;
  stroke: #485054;
}


.base-timer__path-remaining {
  stroke-width: 5px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: #13ACB1;
}

.base-timer__path-remaining.orange {
  color: #F78133;
}

.base-timer__path-remaining.red {
  color: #DE3562;
}

.base-timer__label {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight:600;
}